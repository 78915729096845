import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../config";
import axiosClient from "../../common/api/request";

export const forgetPassword = createAsyncThunk(
  "forget_password",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/password_forgot/`,
        params,
        config
      );
      return data;
    } catch (error) {
      if (error?.response && error?.response?.data) {
        return rejectWithValue(error?.response?.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const resetPassword = createAsyncThunk(
  "reset_password",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/password_reset/`,
        params,
        config
      );
      return data;
    } catch (error) {
      if (error?.response && error?.response?.data) {
        return rejectWithValue(error?.response?.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
