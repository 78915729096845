import React, { useRef, useImperativeHandle } from "react";
import { Input } from "antd";
import PhoneInput from "react-phone-number-input/input";

const MyInput = React.forwardRef((props, ref) => {
  const inputRef = useRef(null);

  useImperativeHandle(
    ref,
    () => {
      return inputRef?.current?.input;
    },
    []
  );
  const handleKeyDown = (e) => {
    if (e.key === "+") {
      e.preventDefault();
    }
  };
  return (
    <Input {...props} ref={inputRef} prefix="+1" onKeyDown={handleKeyDown} />
  );
});

export default function PhoneNumberInput({
  value,
  onChange,
  placeholder,
  isDisabled,
  size,
  readOnly,
  ...props
}) {
  return (
    <PhoneInput
      value={value}
      onChange={onChange}
      placeholder={placeholder ? placeholder : "Phone Number"}
      className="ant-input"
      defaultCountry="US"
      countries={["US"]}
      inputComponent={MyInput}
      disabled={isDisabled ? true : false}
      size={size ? size : null}
      readOnly={readOnly ? true : false}
      {...props}
    />
  );
}
