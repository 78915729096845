import React from "react";
import "./OrderTabModal.scss";
import { Modal, Form, Input, Typography, Select, Checkbox } from "antd";
import useOrderTabModal from "../../hooks/useOrderTabModal";

export default function OrderTabModal({
  isOpened,
  title,
  onOk,
  onCancel,
  roomDetails,
  callWhenOrderRoomUpdated,
  callWhenOrderRoomCreated,
}) {
  const {
    token,
    formRef,
    formData,
    possibleLocations,
    numberValidator,
    loading,
    dispatch,
    roomLoading,
    filterOption,
    resetOrderRoomError,
    roomError,
    users,
    isByMetrc,
    setIsByMetrc,
    isMobile,
  } = useOrderTabModal(
    isOpened,
    onCancel,
    roomDetails,
    callWhenOrderRoomUpdated,
    callWhenOrderRoomCreated
  );
  return (
    <Modal
      className="orderTabModal-cont"
      style={{
        borderTop: `2px solid ${token?.Button?.colorPrimary}`,
      }}
      open={isOpened}
      title={title}
      centered={true}
      okText="Save"
      closeIcon={false}
      cancelButtonProps={{
        className: "orderTabModal-cont__btn",
        size: "small",
      }}
      okButtonProps={{
        size: "small",
        className: "orderTabModal-cont__btn",
        htmlType: "submit",
        type: "primary",
        key: "submit",
        loading: roomLoading && isOpened,
        disabled: roomError ? Object.keys(roomError)?.length !== 0 : false,
      }}
      onCancel={() => {
        onCancel();
        formRef.current.resetFields();
        setIsByMetrc(false);
        if (roomError) {
          dispatch(resetOrderRoomError());
        }
      }}
      onOk={() => {
        formRef.current.submit();
      }}>
      <Form
        ref={formRef}
        layout="vertical"
        className="orderTabModal-cont__form"
        onFinish={(values) => {
          if (roomDetails) {
            onOk({
              ...values,
              delta:
                values?.delta || values?.delta === 0 ? values?.delta : null,
              id: roomDetails?.id,
              is_metrc: isByMetrc,
            });
          } else {
            onOk({
              ...values,
              delta:
                values?.delta || values?.delta === 0 ? values?.delta : null,
              is_metrc: isByMetrc,
            });
          }
        }}
        onFinishFailed={(error) => {}}
        fields={formData}>
        <div className="orderTabModal-cont__form__formItemWrapper">
          <Form.Item
            className="orderTabModal-cont__form__formItemWrapper__formItem"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter tab name",
              },
            ]}
            label={
              <Typography.Text className="orderTabModal-cont__form__formItemWrapper__formItem__text">
                Tab name
              </Typography.Text>
            }
            colon={false}>
            <Input
              type="text"
              placeholder="Tab name"
              onChange={() => {
                if (roomError && roomError["name"]) {
                  dispatch(resetOrderRoomError("name"));
                }
              }}
            />
          </Form.Item>
          <Form.Item
            className="orderTabModal-cont__form__formItemWrapper__formItem"
            style={{
              marginTop: isMobile ? null : "5px",
            }}
            name="delta"
            rules={[
              {
                validator: numberValidator,
              },
            ]}
            label={
              <Typography.Text className="orderTabModal-cont__form__formItemWrapper__formItem__text">
                Delta
              </Typography.Text>
            }
            colon={false}>
            <Input
              placeholder="Delta"
              onChange={() => {
                if (roomError && roomError["delta"]) {
                  dispatch(resetOrderRoomError("delta"));
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="orderTabModal-cont__form__formItemWrapper">
          <Form.Item
            className="orderTabModal-cont__form__formItemWrapper__formItem"
            name="delta_unit"
            rules={[
              {
                required: true,
                message: "Please select delta unit",
              },
            ]}
            label={
              <Typography.Text className="orderTabModal-cont__form__formItemWrapper__formItem__text">
                Delta unit
              </Typography.Text>
            }
            colon={false}>
            <Select
              allowClear
              type="text"
              placeholder="Delta unit"
              onChange={() => {
                if (roomError && roomError["delta_unit"]) {
                  dispatch(resetOrderRoomError("delta_unit"));
                }
              }}
              options={["minutes", "hours", "days", "weeks"]?.map((el) => ({
                label:
                  el?.charAt(0)?.toUpperCase() + el?.slice(1)?.toLowerCase(),
                value: el,
              }))}
            />
          </Form.Item>
          <Form.Item
            className="orderTabModal-cont__form__formItemWrapper__formItem"
            name="sources"
            rules={[
              {
                required: true,
                message: "Please select sources",
              },
            ]}
            label={
              <Typography.Text className="orderTabModal-cont__form__formItemWrapper__formItem__text">
                Sources
              </Typography.Text>
            }
            colon={false}>
            <Select
              showSearch
              allowClear
              mode="multiple"
              options={[
                "WEEDMAPS",
                "WORDPRESS",
                "ROOT_WORDPRESS",
                "CANASALE",
                "LEAFLY",
              ]?.map((el) => ({
                label:
                  el?.replace(/_/g, " ")?.charAt(0)?.toUpperCase() +
                  el?.replace(/_/g, " ")?.slice(1)?.toLowerCase(),
                value: el,
              }))}
              placeholder="Source"
              onChange={() => {
                if (roomError && roomError["sources"]) {
                  dispatch(resetOrderRoomError("sources"));
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="orderTabModal-cont__form__formItemWrapper">
          <Form.Item
            className="orderTabModal-cont__form__formItemWrapper__formItemLong"
            name="locations"
            rules={[
              {
                required: true,
                message: "Please select region",
              },
            ]}
            label={
              <Typography.Text className="orderTabModal-cont__form__formItemWrapper__formItemLong__text">
                Region
              </Typography.Text>
            }
            colon={false}>
            <Select
              showSearch
              allowClear
              mode="multiple"
              options={possibleLocations?.map((el) => ({
                label: el?.name,
                value: el?.id,
              }))}
              placeholder="Region"
              filterOption={filterOption}
              onChange={() => {
                if (roomError && roomError["locations"]) {
                  dispatch(resetOrderRoomError("locations"));
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="orderTabModal-cont__form__formItemWrapper">
          <Form.Item
            className="orderTabModal-cont__form__formItemWrapper__formItemLong"
            name="users"
            rules={[
              {
                required: true,
                message: "Please select user",
              },
            ]}
            label={
              <Typography.Text className="orderTabModal-cont__form__formItemWrapper__formItemLong__text">
                Users
              </Typography.Text>
            }
            colon={false}>
            <Select
              showSearch
              allowClear
              mode="multiple"
              placeholder="Users"
              loading={loading}
              filterOption={filterOption}
              onChange={() => {
                if (roomError && roomError["users"]) {
                  dispatch(resetOrderRoomError("users"));
                }
              }}
              options={users?.map((el) => ({
                label: `${el?.first_name ? el?.first_name : ""} ${
                  el?.last_name ? el?.last_name : ""
                } ${el?.email ? el?.email : ""}`,
                value: el?.id,
              }))}
            />
          </Form.Item>
        </div>
        <div className="orderTabModal-cont__form__formItemWrapper">
          <Form.Item
            className="orderTabModal-cont__form__formItemWrapper__formItemLong"
            name="is_metrc"
            colon={false}>
            <Checkbox
              checked={isByMetrc}
              onChange={() => {
                setIsByMetrc(!isByMetrc);
                if (roomError && roomError["is_metrc"]) {
                  dispatch(resetOrderRoomError("is_metrc"));
                }
              }}>
              Metrc
            </Checkbox>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
}
