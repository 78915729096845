import { useEffect, useState } from "react";
import axiosClient from "../common/api/request";
import config from "../config";

export default function useCaptcha(
  setValue,
  willCaptchaRegenerated,
  setWillCaptchaRegenerated
) {
  const [captcha, setCaptcha] = useState(null)
  const [loading, setLoading] = useState(false)
  const getCaptcha = async (params) => {
    setLoading(true);
    try {
      const res = await axiosClient.post(`${config.API_URL}/captcha/`, params);
      const decoded_image = atob(res?.captcha_image);
      setCaptcha({ ...res, decoded_image });
      setValue({ key: res.captcha_key, value: "" })
      setLoading(false);
      setWillCaptchaRegenerated(false)
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  useEffect(() => {
    getCaptcha();
  }, []);

  useEffect(() => {
    if (willCaptchaRegenerated) {
      getCaptcha();
    }
  }, [willCaptchaRegenerated]);

  return {
    captcha,
    loading,
    getCaptcha
  };
}
