import React, { useState, useRef } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Divider, Input, Select, Button, Checkbox } from "antd";

export default function CustomSelect({
  addBtnText,
  size,
  placeholder,
  options,
  addItem,
  loading,
  selectedItem,
  setSelectedItem,
  error,
  resetError,
  disabled,
  allowClear,
  clear,
}) {
  const [details, setDetails] = useState({
    city: "",
    address1: "",
    state: "",
    zipcode: "",
  });
  const onDetailChnage = (key, value) => {
    setDetails({
      ...details,
      [key]: value,
    });
  };

  const [isNew, setIsNew] = useState(false);
  return (
    <Select
      style={{
        width: "100%",
      }}
      placeholder={placeholder}
      allowClear={allowClear}
      onClear={() => {
        if (allowClear) {
          clear();
        }
      }}
      disabled={loading || disabled}
      status={error ? "error" : null}
      size={size ? size : ""}
      value={
        selectedItem
          ? `${selectedItem?.address1 ? `${selectedItem?.address1},` : ""}
          ${selectedItem?.city ? `${selectedItem?.city},` : ""}  
          ${selectedItem?.state ? `${selectedItem?.state},` : ""}  
          ${selectedItem?.zipcode ? selectedItem?.zipcode : ""}`
          : null
      }
      onChange={(val) => {
        const foundAddress = options?.find((el) => el?.id === val);
        setSelectedItem(foundAddress);
        if (error) {
          resetError();
        }
      }}
      dropdownRender={(menu) => (
        <div
          style={{
            width: "100%",
          }}>
          {menu}
          <Divider
            style={{
              margin: "8px 0",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              width: "100%",
            }}>
            <Checkbox
              onChange={() => {
                setIsNew(!isNew);
              }}
              checked={isNew}
              style={{
                marginLeft: "10px",
              }}>
              New shipping address
            </Checkbox>
            {isNew ? (
              <>
                <Input
                  style={{
                    width: "100%",
                  }}
                  placeholder="Address"
                  value={details?.address1}
                  onChange={(e) => {
                    onDetailChnage("address1", e?.target?.value);
                  }}
                  onKeyDown={(e) => e.stopPropagation()}
                  size="small"
                />
                <Input
                  style={{
                    width: "100%",
                  }}
                  placeholder="City"
                  value={details?.city}
                  onChange={(e) => {
                    onDetailChnage("city", e?.target?.value);
                  }}
                  onKeyDown={(e) => e.stopPropagation()}
                  size="small"
                />
                <Input
                  style={{
                    width: "100%",
                  }}
                  placeholder="State"
                  value={details?.state}
                  onChange={(e) => {
                    onDetailChnage("state", e?.target?.value);
                  }}
                  onKeyDown={(e) => e.stopPropagation()}
                  size="small"
                />
                <Input
                  style={{
                    width: "100%",
                  }}
                  placeholder="Zip code"
                  value={details?.zipcode}
                  onChange={(e) => {
                    onDetailChnage("zipcode", e?.target?.value);
                  }}
                  onKeyDown={(e) => e.stopPropagation()}
                  size="small"
                />
                <Button
                  type="text"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    addItem(details);
                    setDetails({
                      city: "",
                      address1: "",
                      state: "",
                      zipcode: "",
                    });
                    setIsNew(false);
                  }}
                  disabled={
                    !details?.state ||
                    !details?.city ||
                    !details?.address1 ||
                    !details?.zipcode ||
                    loading
                  }
                  size="small">
                  {addBtnText}
                </Button>
              </>
            ) : null}
          </div>
        </div>
      )}
      options={options?.map((item) => ({
        label: `${item?.address1 ? `${item?.address1},` : ""} 
        ${item?.city ? `${item?.city},` : ""}
        ${item?.state ? `${item?.state},` : ""}
         ${item?.zipcode ? item?.zipcode : ""}`,
        value: item?.id,
      }))}
    />
  );
}
