import "./EditPackages.scss";
import {
  Modal,
  Form,
  Select,
  DatePicker,
  Typography,
  Input,
  Switch,
} from "antd";
import useEditMenuModal from "../../hooks/useEditMenuModal";
import { updateMenu } from "../../features/menu/menuActions";
import { useEffect, useState } from "react";
import {
  getCategoriesByParent,
  getCategoriesPerPage,
} from "../../features/category/categoryActions";
import { useSelector } from "react-redux";
import {
  getBrandProducts,
  getBrands,
} from "../../features/brands/brandsActions";
import useWindowWidth from "../../hooks/useWindowWidth";
import Utils from "../../utils";
import { inTakePackages } from "../../features/metrics/metricActions";
import { resetError } from "../../features/metrics/metricSlice";

import { getSafes } from "../../features/safe/safeAction";
import { useNavigate } from "react-router";
const { Option } = Select;
export default function EditPackages({
  isOpened,
  title,
  onCancel,
  onSubmit,
  selectedPackage,
  api,
  error,
  loading,
}) {
  const { button, formRef, dispatch } = useEditMenuModal();
  const categories = useSelector(
    (state) => state?.categorys?.categoriesPerPage
  );
  const { isMobile } = useWindowWidth();
  const isCategoriesLoading = useSelector(
    (state) => state?.categorys?.perPageLoading
  );
  const [isActive, setIsActive] = useState(false);

  const edit = (id, updated_name) => {
    return dispatch(updateMenu({ id, updated_name }));
  };
  const openNotificationWithIcon = (type, title, text) => {
    api[type]({
      message: title,
      description: text,
      placement: "top",
    });
  };
  const [safe, setSafe] = useState(null);
  const [mainCategories, setMainCategories] = useState([]);
  const [selectCategory, setSelectCategory] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [children, setChildren] = useState([]);
  const { results: brands } = useSelector((state) => state?.brands?.brands);
  const { results: brandProducts } = useSelector(
    (state) => state?.brands?.brandProducts
  );
  const METRC_CANASALE_UNIT_MAPPING = {
    Milligrams: "mg",
    Gram: "g",
    Kilograms: "kg",
    Each: "unit",
    Ounces: "oz",
    Pounds: "lb",
  };

  useEffect(() => {
    dispatch(getSafes())?.then((data) => {
      const safe = data?.payload?.results[0];
      setSafe(safe);
    });
  }, []);

  useEffect(() => {
    if (selectCategory) {
      dispatch(
        getCategoriesByParent({ parent: selectCategory.id, per_page: 100000 })
      ).then((res) => {
        setChildren((prev) => {
          if (prev?.length > 0) {
            if (selectCategory.parent === prev[prev.length - 1].id) {
              return [
                ...prev,
                { ...selectCategory, options: [...res.payload.results] },
              ];
            } else if (selectCategory.parent === null) {
              return [{ ...selectCategory, options: [...res.payload.results] }];
            } else {
              const myArray = [...prev];
              const index = myArray.findIndex(
                (el) => el.parent === selectCategory.parent
              );
              myArray.splice(index);
              myArray.push({
                ...selectCategory,
                options: [...res.payload.results],
              });
              return [...myArray];
            }
          } else {
            return [{ ...selectCategory, options: [...res.payload.results] }];
          }
        });
      });
    }
  }, [selectCategory]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const handleMain = (options) => {
    setSelectCategory({ ...options });
  };
  const handleSelectBrand = (_, options) => {
    setSelectedBrand({ ...options });
  };

  const handleSelectProduct = (options) => {
    setSelectedProduct({ ...options });
  };

  useEffect(() => {
    // if (!categories?.results?.length) {
    dispatch(getCategoriesPerPage({ per_page: 100000 }));
    // }
  }, []);

  useEffect(() => {
    if (selectedBrand) {
      dispatch(getBrandProducts({ id: selectedBrand.value, per_page: 100000 }));
    }
  }, [selectedBrand]);

  useEffect(() => {
    if (!brands) {
      dispatch(
        getBrands({
          per_page: 10000,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (categories.results && mainCategories.length === 0) {
      const filtered = categories.results.filter(
        (el) => el.group_name === "Main Categories"
      );
      setMainCategories(filtered);
    }
  }, [categories]);

  const onOk = async (id, updated_name) => {
    const data = await edit(id, updated_name);

    if (data.type === "updateMenu/fulfilled") {
      onCancel();
      formRef?.current?.setFields([
        {
          name: "name",
          value: "",
          errors: [],
        },
      ]);
    }
  };

  useEffect(() => {
    if (error && !loading && isOpened) {
      for (let key in error) {
        openNotificationWithIcon("error", key, error[0]?.[key]?.[0]);
      }
      dispatch(resetError());
    }
  }, [error, loading]);

  useEffect(() => {
    if (error && !loading) {
      for (let key in error[0]) {
        formRef?.current?.setFields([
          {
            name: key,
            errors: error[0][key],
          },
        ]);
      }
    }
  }, [loading, error, isOpened]);

  return (
    <Modal
      width={isMobile ? "100%" : 500}
      open={isOpened}
      title={title}
      onOk={() => {
        formRef.current.submit();
        if (error) {
          dispatch(resetError());
        }
      }}
      okText="Save"
      closeIcon={false}
      centered
      onCancel={() => {
        formRef?.current?.resetFields();
        setIsActive(false);
        if (error) {
          dispatch(resetError());
        }
        onCancel();
      }}
      style={{
        borderTop: `2px solid ${button?.colorPrimary}`,
      }}
      className="editpackages-cont"
      cancelButtonProps={{
        className: "editpackages-cont__btn",
        size: "small",
      }}
      okButtonProps={{
        size: "small",
        loading: loading,
        htmlType: "submit",
        type: "primary",
        disabled: error ? Object.keys(error)?.length !== 0 : false,
        className: "editpackages-cont__btn",
      }}>
      <Form
        layout="vertical"
        ref={formRef}
        className="editpackages-cont__form"
        onFinish={(values) => {
          const categories = children.map((el) => {
            const { options, ...rest } = el;
            return rest.id;
          });
          const expiration_date = values?.expiration
            ? Utils.getMetricFormattedDate(values?.expiration)
            : null;
          const sell_by_date = values?.sellBy
            ? Utils.getMetricFormattedDate(values?.sellBy)
            : null;
          if (!isActive) {
            dispatch(
              inTakePackages({
                id: selectedPackage.id,
                brand: values.brand,
                product: values.product,
                categories,
                expiration_date,
                sell_by_date,
                safe: safe?.id,
                variant: values.price
                  ? {
                      cost: values.cost,
                      price: values.price,
                      unit: METRC_CANASALE_UNIT_MAPPING[
                        selectedPackage?.item?.unit_of_measure_name
                      ],
                      count: selectedPackage.quantity,
                    }
                  : {
                      cost: values.cost,
                      unit: METRC_CANASALE_UNIT_MAPPING[
                        selectedPackage?.item?.unit_of_measure_name
                      ],
                      count: selectedPackage.quantity,
                    },
              })
            ).then((data) => {
              if (data && data.payload) {
                if (data.meta.requestStatus === "fulfilled") {
                  onSubmit();
                  setIsActive(false);
                  formRef?.current?.resetFields();
                }
              }
            });
          } else {
            dispatch(
              inTakePackages({
                id: selectedPackage.id,
                expiration_date,
                sell_by_date,
                safe: safe?.id,
                variant: values.price
                  ? {
                      cost: values.cost,
                      price: values.price,
                      unit: METRC_CANASALE_UNIT_MAPPING[
                        selectedPackage?.item?.unit_of_measure_name
                      ],
                      count: selectedPackage.quantity,
                    }
                  : {
                      cost: values.cost,
                      unit: METRC_CANASALE_UNIT_MAPPING[
                        selectedPackage?.item?.unit_of_measure_name
                      ],
                      count: selectedPackage.quantity,
                    },
              })
            ).then((data) => {
              if (data && data.payload) {
                if (data.meta.requestStatus === "fulfilled") {
                  onSubmit();
                  setIsActive(false);
                  formRef?.current?.resetFields();
                }
              }
            });
          }
        }}
        onFinishFailed={(error) => {}}>
        <div className="editpackages-cont__labels">
          <p>
            <Typography.Text>Label: {selectedPackage.label}</Typography.Text>
          </p>
          <p>
            <Typography.Text>
              Quantity: {selectedPackage.quantity}
            </Typography.Text>
          </p>
          <p>
            <Typography.Text>
              Metric Product Name: {selectedPackage.item?.name}
            </Typography.Text>
          </p>
          <p>
            <Typography.Text>
              Metric Unit of Measurement: {selectedPackage.unit_of_measure_name}
            </Typography.Text>
          </p>
          <p>
            <Typography.Text>
              Weight: {selectedPackage.item?.unit_weight}
            </Typography.Text>
          </p>
          <p>
            <Typography.Text>
              Destination: <b>Safe</b>
            </Typography.Text>
          </p>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "flex-end",
            gap: "8px",
          }}>
          <Typography.Text>Custom Item : </Typography.Text>
          <Switch
            size="small"
            checked={isActive}
            onChange={(isActive) => {
              setIsActive(isActive);
              if (isActive) {
                formRef?.current?.resetFields([
                  "brand",
                  "product",
                  "categories",
                ]);
                formRef?.current?.setFieldValue("brand", undefined);
                formRef?.current?.setFieldValue("product", undefined);
                formRef?.current?.setFieldValue("categories", undefined);
              }
            }}
          />
        </div>
        <Form.Item
          style={{ width: "100%" }}
          name="brand"
          rules={
            !isActive
              ? [
                  {
                    required: true,
                    message: "Brand is a required field",
                  },
                ]
              : null
          }
          label={<Typography.Text>Brand</Typography.Text>}
          colon={false}>
          <Select
            showSearch={true}
            placeholder={"Brand"}
            filterOption={(input, option) => {
              return (option?.label ?? "")
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
            disabled={isActive}
            onChange={handleSelectBrand}
            options={brands?.map((brand) => {
              return {
                label: brand.value,
                value: brand.id,
              };
            })}
          />
        </Form.Item>
        <Form.Item
          style={{ width: "100%" }}
          name="product"
          rules={
            !isActive
              ? [
                  {
                    required: true,
                    message: "Product is a required field",
                  },
                ]
              : null
          }
          label={<Typography.Text>Product</Typography.Text>}
          colon={false}>
          <Select
            disabled={isActive}
            showSearch={true}
            filterOption={(input, option) => {
              return (option?.label ?? "")
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
            placeholder={"Product"}
            onChange={(_, options) => {
              const { label, value, ...rest } = options;
              handleSelectProduct(rest);
            }}
            options={brandProducts?.map((el) => {
              return {
                value: el.id,
                label: el.name,
              };
            })}
          />
        </Form.Item>
        <Form.Item
          name="cost"
          className="editpackages-cont__form__formItem"
          colon={false}
          rules={[
            {
              required: true,
              message: "Cost is a required field",
            },
            {
              validator: (_, value) => {
                return new Promise((resolve, reject) => {
                  if (
                    value !== "" &&
                    value !== undefined &&
                    !/^(\d+(\.\d+)?|-\d+(\.\d+)?)$/.test(value)
                  ) {
                    reject("Cost must be a numeric value");
                  } else if (value !== "" && parseFloat(value) < 0) {
                    reject("Cost must be 0 or a positive number.");
                  } else {
                    resolve();
                  }
                });
              },
            },
          ]}
          label={<Typography.Text strong>Item Cost</Typography.Text>}>
          <Input placeholder="Cost" />
        </Form.Item>
        <Form.Item
          name="price"
          className="editpackages-cont__form__formItem"
          colon={false}
          label={<Typography.Text strong>Item Price</Typography.Text>}>
          <Input placeholder="Price" />
        </Form.Item>
        <Form.Item
          name="categories"
          className="editpackages-cont__form__formItem"
          // rules={[
          //   {
          //     required: true,
          //     message: "edit name can't be empty",
          //   },
          // ]}
          colon={false}
          rules={
            !isActive
              ? [
                  {
                    required: true,
                    message: "Category is a required field",
                  },
                ]
              : null
          }
          label={<Typography.Text strong>Item Category</Typography.Text>}>
          <Select
            disabled={isActive}
            placeholder={"Category"}
            loading={isCategoriesLoading}
            onChange={(_, options) => {
              if (error && error["categories"]) {
                dispatch(resetError("first_name"));
                // formRef.current.resetFields(['categories'])
              }
              const { value, ...remaining } = options;
              handleMain(remaining);
            }}
            options={mainCategories?.map((el) => {
              return {
                ...el,
                value: el.name,
              };
            })}
          />
        </Form.Item>
        {children?.map((item) => {
          return item.options.length > 0 ? (
            <Form.Item
              name={item.slug}
              label={<Typography.Text>{item.name + " Type"}</Typography.Text>}
              colon={false}>
              <Select
                placeholder={"Select"}
                onChange={(_, options) => {
                  const { value, ...remaining } = options;
                  handleMain(remaining);
                }}
                options={item.options?.map((el) => ({
                  ...el,
                  value: el.name,
                }))}
              />
            </Form.Item>
          ) : null;
        })}
        <Form.Item
          name="expiration"
          className="editpackages-cont__form__formItem"
          colon={false}
          label={<Typography.Text strong>Expiration Date</Typography.Text>}>
          <DatePicker
            className="addPromotion-cont__form__descriptionWrapper__description__formItem__rangePicker"
            format={"YYYY-MM-DD"}
            size="large"
          />
        </Form.Item>
        {/* <Form.Item
          name="batchID"
          className="editpackages-cont__form__formItem"
          colon={false}
          label={
            <Typography.Text strong>Batch ID</Typography.Text>

          }>

          <Input />
        </Form.Item> */}
        <Form.Item
          name="sellBy"
          className="editpackages-cont__form__formItem"
          colon={false}
          label={<Typography.Text strong>Sell By</Typography.Text>}>
          <DatePicker
            className="addPromotion-cont__form__descriptionWrapper__description__formItem__rangePicker"
            format={"YYYY-MM-DD"}
            size="large"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
