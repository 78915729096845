import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../../config";
import axiosClient from "../../../common/api/request";
export const getReportsList = createAsyncThunk(
  "get_reports_list",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL_V2}/reports/`,
        { params },
        config
      );
      return data;
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data &&
        typeof error?.response?.data === "object"
      ) {
        return rejectWithValue(error?.response?.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
export const postReport = createAsyncThunk(
  "post_report",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.post(
        `${config.API_URL_V2}/reports/`,
        {
          ...params,
          // category: params?.category?.id,
        },
        config
      );
      return {
        ...data,
        category: params?.category,
      };
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data &&
        typeof error?.response?.data === "object"
      ) {
        return rejectWithValue(error?.response?.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
export const getReportData = createAsyncThunk(
  "get_report_data",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL_V2}/reports/${params?.id}/`,
        params,
        config
      );
      return data;
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data &&
        typeof error?.response?.data === "object"
      ) {
        return rejectWithValue(error?.response?.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
export const patchReport = createAsyncThunk(
  "patch_report",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.patch(
        `${config.API_URL_V2}/reports/${params?.id}/`,
        params,
        config
      );
      return data;
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data &&
        typeof error?.response?.data === "object"
      ) {
        return rejectWithValue(error?.response?.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
export const deleteReport = createAsyncThunk(
  "delete_report",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.delete(
        `${config.API_URL_V2}/reports/${params?.id}/`,
        params,
        config
      );
      return {
        data,
        id: params?.id,
      };
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data &&
        typeof error?.response?.data === "object"
      ) {
        return rejectWithValue(error?.response?.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
