import LandingSpin from "../LandingSpin/LandingSpin";
import RegenerateCaptcha from "../../assets/RegenerateCaptcha.svg";
import useCaptcha from "../../hooks/useCaptcha";
import "./Captcha.scss";

function Captcha({
  error,
  setError,
  values,
  setValue,
  willCaptchaRegenerated,
  setWillCaptchaRegenerated,
}) {
  const { captcha, loading, getCaptcha } = useCaptcha(
    setValue,
    willCaptchaRegenerated,
    setWillCaptchaRegenerated
  );
  return captcha ? (
    <>
      <div
        className={"input_container"}
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
        }}>
        <div>
          {" "}
          {loading ? (
            <LandingSpin
              size={25}
              color="#61BC18"
              secondColor="rgba(173,62,240,255)"
            />
          ) : (
            <img
              alt="Captcha image"
              src={`data:image/png;base64,${captcha?.captcha_image}`}
            />
          )}
        </div>

        <button
          style={{
            background: "transparent",
            width: "100%",
            maxWidth: "40px",
            height: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "auto",
          }}
          onClick={() => {
            getCaptcha();
            setError("");
          }}>
          <img src={RegenerateCaptcha} alt="Captcha reset" />
        </button>
      </div>
      <div className={"input_container"}>
        <div className={"input_container__formItemWrapper"}>
          <input
            type="Value"
            placeholder="Enter Captcha Code"
            value={values.value}
            onChange={(e) => {
              if (error) {
                setError("");
              }
              if (!e.target.value) {
                setError("Enter Captcha");
              }
              setValue({ ...values, value: e.target.value });
            }}
          />
          {error ? (
            <p className={"input_container__formItemWrapper__errorText"}>
              {error}
            </p>
          ) : null}
        </div>
      </div>
    </>
  ) : null;
}

export default Captcha;