import React from "react";
import "./TeamUserModal.scss";
import { Modal, Form, Input, Typography, Select } from "antd";
import useTeamUserModal from "../../hooks/useTeamUserModal";
import { PhoneNumberInput } from "../../components";
export default function TeamUserModal({
  isOpened,
  title,
  onOk,
  onCancel,
  roles,
  user,
}) {
  const {
    token,
    formRef,
    emailValidator,
    formData,
    loading,
    error,
    dispatch,
    resetTeamMemberError,
    handleFiledsChanged,
    isFieldsChanged,
    setIsFieldsChanged,
    setChangedFields,
    filterGroupOption,
  } = useTeamUserModal(user, isOpened, roles, onCancel);

  return (
    <Modal
      className="teamUserModal-cont"
      open={isOpened}
      title={title}
      centered={true}
      okText="Save"
      closeIcon={false}
      style={{
        borderTop: `2px solid ${token?.Button?.colorPrimary}`,
      }}
      cancelButtonProps={{
        className: "teamUserModal-cont__btn",
        size: "small",
      }}
      okButtonProps={{
        size: "small",
        className: "teamUserModal-cont__btn",
        htmlType: "submit",
        type: "primary",
        key: "submit",
        loading: loading && isOpened,
        disabled: error ? Object.keys(error)?.length !== 0 : false,
      }}
      onCancel={() => {
        onCancel();
        formRef.current.resetFields();
        dispatch(resetTeamMemberError());
        setIsFieldsChanged(false);
        setChangedFields({});
      }}
      onOk={() => {
        formRef.current.submit();
        dispatch(resetTeamMemberError());
      }}>
      <Form
        ref={formRef}
        layout="vertical"
        className="teamUserModal-cont__form"
        onFieldsChange={(changedFields) => {
          handleFiledsChanged({
            name: changedFields[0]?.name[0],
            value: changedFields[0]?.value,
          });
        }}
        onFinish={(values) => {
          const modifiedValues = {
            ...values,
            phone: values?.phone ? values?.phone : "",
            // Add or modify fields as needed
          };
          if (!user) {
            onOk({
              ...modifiedValues,
            });
          } else {
            if (isFieldsChanged) {
              onOk({
                ...modifiedValues,
                id: user?.id,
              });
            } else {
              onCancel();
            }
          }
        }}
        onFinishFailed={(error) => {}}
        fields={formData}>
        <div className="teamUserModal-cont__form__formItemWrapper">
          <Form.Item
            className="teamUserModal-cont__form__formItemWrapper__formItem"
            name="first_name"
            rules={[
              {
                required: true,
                message: "Please enter your first name",
              },
            ]}
            label={
              <Typography.Text className="teamUserModal-cont__form__formItemWrapper__formItem__text">
                First Name
              </Typography.Text>
            }
            colon={false}>
            <Input
              type="text"
              placeholder="First Name"
              onChange={() => {
                if (error && error["first_name"]) {
                  dispatch(resetTeamMemberError("first_name"));
                }
              }}
            />
          </Form.Item>
          <Form.Item
            className="teamUserModal-cont__form__formItemWrapper__formItem"
            name="last_name"
            rules={[
              {
                required: true,
                message: "Please enter your last name",
              },
            ]}
            label={
              <Typography.Text className="teamUserModal-cont__form__formItemWrapper__formItem__text">
                Last Name
              </Typography.Text>
            }
            colon={false}>
            <Input
              type="text"
              placeholder="Last Name"
              onChange={() => {
                if (error && error["last_name"]) {
                  dispatch(resetTeamMemberError("last_name"));
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="teamUserModal-cont__form__formItemWrapper">
          <Form.Item
            style={{
              marginTop: "5px",
            }}
            name="phone"
            className="teamUserModal-cont__form__formItemWrapper__formItem"
            label={
              <Typography.Text className="teamUserModal-cont__form__formItemWrapper__formItem__text">
                Phone Number
              </Typography.Text>
            }
            colon={false}>
            <PhoneNumberInput
              onChange={() => {
                if (error && error["phone"]) {
                  dispatch(resetTeamMemberError("phone"));
                  formRef.current.setFields([
                    {
                      name: "phone",
                      errors: [], // Clear the error message for the date_range field
                    },
                  ]);
                }
              }}
            />
          </Form.Item>
          <Form.Item
            className="teamUserModal-cont__form__formItemWrapper__formItem"
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter your email",
              },
              // for custom validation
              {
                validator: emailValidator,
              },
            ]}
            label={
              <Typography.Text
                className="teamUserModal-cont__form__formItemWrapper__formItem__text"
                // style={{
                //   fontFamily: `${token?.Typography?.fontFamily}`,
                // }}
              >
                Email
              </Typography.Text>
            }
            colon={false}>
            <Input
              type="text"
              placeholder="Email"
              onChange={() => {
                if (error && error["email"]) {
                  dispatch(resetTeamMemberError("email"));
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="creationModal-cont__form__formItemWrapper">
          <Form.Item
            className="creationModal-cont__form__formItemWrapper__formItemSelect"
            name="telegram_token"
            label={
              <Typography.Text className="creationModal-cont__form__formItemWrapper__formItem__text">
                Telegram token
              </Typography.Text>
            }
            colon={false}>
            <Input
              type="text"
              placeholder="Telegram token"
              onChange={() => {
                if (error && error["telegram_token"]) {
                  dispatch(resetTeamMemberError("telegram_token"));
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="creationModal-cont__form__formItemWrapper">
          <Form.Item
            className="creationModal-cont__form__formItemWrapper__formItemSelect"
            name="groups"
            // rules={[
            //   {
            //     required: true,
            //     message: "A user must have at least one group.",
            //   },
            // ]}
            label={
              <Typography.Text className="creationModal-cont__form__formItemWrapper__formItem__text">
                User Groups
              </Typography.Text>
            }
            colon={false}>
            <Select
              className="creationModal-cont__form__formItemWrapper__formItemSelect__select"
              placeholder="Select groups"
              onChange={() => {
                if (error && error["groups"]) {
                  dispatch(resetTeamMemberError("groups"));
                }
              }}
              options={roles?.map((el) => ({
                label: el?.label,
                value: el?.id,
              }))}
              mode="multiple"
              filterOption={filterGroupOption}
              optionFilterProp="children"
            />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
}
