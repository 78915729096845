import { createSlice } from "@reduxjs/toolkit";
import {
  addSubMenu,
  addMenu,
  getItems,
  getMenu,
  getSubMenus,
  resetSubMenus,
  removeSubMenu,
  removeMenu,
  updateMenu,
  postImage,
  editSubMenu,
  createMenuItem,
  updateMenuItem,
  get_possible_sub_menus,
  sync_menu_items,
} from "./menuActions";

const initialState = {
  isEditOpened: false,
  loading: false,
  userInfo: null,
  menu: {},
  menuItems: {},
  subMenu: {},
  error: null,
  success: false,
  selectedImageUrl: null,
  params: {},
  possible_sub_menus: [],
  menuLoading: false,
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    resetSelectedImage(state) {
      state.selectedImageUrl = null;
    },
    setParams(state, action) {
      state.params = action.payload;
    },
    setIsEditOpened(state, action) {
      state.isEditOpened = action.payload;
    },
    resetErrors(state) {
      state.error = null;
      state.success = false;
    },
    resetMenuItems(state) {
      state.menuItems = {};
      state.subMenu = {};
    },
    resetMenuSliceFields: (state, { payload }) => {
      if (Array.isArray(payload?.fields)) {
        const { fields } = payload;
        for (let i = 0; i < fields?.length; i++) {
          state[fields[i]] = initialState[fields[i]];
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(getItems.fulfilled, (state, action) => {
        state.menuItems = action.payload;
        state.loading = false;
      })
      .addCase(getItems.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(getMenu.pending, (state) => {
        state.loading = true;
        state.menuLoading = true;
      })
      .addCase(getMenu.fulfilled, (state, action) => {
        state.menu = action.payload;
        state.loading = false;
        state.menuLoading = false;
      })
      .addCase(getMenu.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
        state.menuLoading = false;
      })
      .addCase(getSubMenus.pending, (state) => {
        state.loading = true;
        state.menuItems = {};
      })
      .addCase(resetSubMenus.pending, (state) => {
        state.subMenu = {};
      })
      .addCase(getSubMenus.fulfilled, (state, action) => {
        state.subMenu = action.payload;
        state.loading = false;
      })
      .addCase(getSubMenus.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(addSubMenu.pending, (state) => {
        state.loading = true;
      })
      .addCase(addSubMenu.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addSubMenu.fulfilled, (state, action) => {
        state.subMenu.results = [...state.subMenu.results, action.payload];
        state.loading = false;
      })
      .addCase(addMenu.pending, (state) => {
        state.loading = true;
      })
      .addCase(addMenu.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(addMenu.fulfilled, (state, action) => {
        state.menu.results?.push(action.payload);
        state.loading = false;
      })
      .addCase(editSubMenu.pending, (state) => {
        state.loading = true;
      })
      .addCase(editSubMenu.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(editSubMenu.fulfilled, (state, action) => {
        state.subMenu.results = state.subMenu.results.map((el) => {
          if (el.id === action.payload.id) {
            return action.payload;
          }
          return el;
        });
        state.loading = false;
      })
      .addCase(removeSubMenu.fulfilled, (state, action) => {
        state.subMenu.results = state.subMenu.results.filter(
          (item) => item.id !== action.payload
        );
        state.loading = false;
      })
      .addCase(removeMenu.fulfilled, (state, action) => {
        state.menu.results = state.menu.results.filter(
          (el) => el.id !== +action.payload
        );
      })
      .addCase(updateMenu.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateMenu.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateMenu.fulfilled, (state, action) => {
        state.menu.results = state.menu.results?.map((el) => {
          if (el.id === +action.payload.id) {
            el.name = action.payload.name;
          }

          return el;
        });
      })
      .addCase(postImage.fulfilled, (state, action) => {
        state.selectedImageUrl = action.payload.image;
      })
      .addCase(createMenuItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(createMenuItem.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(createMenuItem.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateMenuItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateMenuItem.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(updateMenuItem.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(get_possible_sub_menus.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_possible_sub_menus.fulfilled, (state, action) => {
        state.possible_sub_menus = action?.payload;
        state.loading = false;
      })
      .addCase(get_possible_sub_menus.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(sync_menu_items.pending, (state) => {
        state.loading = true;
      })
      .addCase(sync_menu_items.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(sync_menu_items.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  resetSelectedImage,
  setParams,
  setIsEditOpened,
  resetErrors,
  resetMenuSliceFields,
  resetMenuItems,
} = menuSlice.actions;
export default menuSlice.reducer;
