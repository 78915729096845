import React from "react";
import "./CreatePatientModal.scss";

import {
  Modal,
  Form,
  Input,
  Typography,
  DatePicker,
  Button,
  Divider,
  Upload,
  Progress,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { PhoneNumberInput } from "../../components";
import useCreatePatientModal from "../../hooks/useCreatePatientModal";
export default function CreatePatientModal({
  isOpened,
  title,
  onOk,
  onCancel,
  patient,
}) {
  const {
    token,
    formRef,
    dateFormat,
    setImageUrl,
    onImageRemove,
    imageUrl,
    emailValidator,
    getFormattedDate,
    medImageUrl,
    onMedImageRemove,
    formData,
    setFormData,
    dispatch,
    resetError,
    loading,
    error,
    postImage,
    progress,
    postMedImage,
    medProgress,
    setMedImageUrl,
    handleIdImageChange,
    handlemedImageChange,
    width
  } = useCreatePatientModal(isOpened, patient, onCancel);
  return (
    <Modal
      className="createPatientModal-cont"
      open={isOpened}
      title={title}
      centered={true}
      okText="Save"
      closeIcon={false}
      cancelButtonProps={{
        className: "createPatientModal-cont__btn",
        size: "small",
      }}
      okButtonProps={{
        size: "small",
        className: "createPatientModal-cont__btn",
        htmlType: "submit",
        type: "primary",
        key: "submit",
        loading: loading && isOpened,
        disabled: error ? Object.keys(error)?.length !== 0 : false,
      }}
      onOk={() => {
        if (error) {
          dispatch(resetError());
        }
        formRef?.current?.submit();
      }}
      onCancel={() => {
        onCancel();
        setImageUrl(null);
        setMedImageUrl(null);
        setFormData([]);
        formRef.current.resetFields();
        dispatch(resetError());
      }}
      style={{
        borderTop: `2px solid ${token?.Button?.colorPrimary}`,
      }}>
      <Form
        className="createPatientModal-cont__form"
        ref={formRef}
        layout="vertical"
        onFinish={(values) => {
          const formatDateFromArray = (dateArray) => {
            if (dateArray?.length !== 3) {
              return "Invalid Date Array"; // Handle invalid input
            }

            return [dateArray[0], dateArray[2], dateArray[1]].join("-");
          };
          const modifiedvalues = {
            ...values,
            phone_number: values?.phone_number ? values?.phone_number : "",
            has_medical_recommendation: Boolean(values?.medical_recommendation),
            medical_recommendation: {
              text: values?.medical_recommendation,
            },
            // id_img: imageUrl?.url,
            rec_exp_date: values?.rec_exp_date
              ? formatDateFromArray(
                  getFormattedDate(values?.rec_exp_date)?.split(".")?.reverse()
                )
              : null,
            dob: values?.dob
              ? formatDateFromArray(
                  getFormattedDate(values?.dob)?.split(".")?.reverse()
                )
              : null,
            patient_id_exp_date: values?.patient_id_exp_date
              ? formatDateFromArray(
                  getFormattedDate(values?.patient_id_exp_date)
                    ?.split(".")
                    ?.reverse()
                )
              : null,
            id_image_url: imageUrl?.url ? imageUrl?.url : null,
            med_rec_image_url: medImageUrl?.url ? medImageUrl?.url : null,
            // Add or modify fields as needed
          };
          onOk(modifiedvalues);
        }}
        onFinishFailed={(error) => {}}
        fields={formData}>
        <div className="createPatientModal-cont__form__formItemWrapper">
          <Form.Item
            className="createPatientModal-cont__form__formItemWrapper__formItem"
            name="first_name"
            rules={[
              {
                required: true,
                message: "Please enter your First Name",
              },
            ]}
            label={
              <Typography.Text
                className="createPatientModal-cont__form__formItemWrapper__formItem__text"
                // style={{
                //   fontFamily: `${token?.Typography?.fontFamily}`,
                // }}
              >
                First Name
              </Typography.Text>
            }
            colon={false}>
            <Input
              type="text"
              placeholder="first Name"
              onChange={() => {
                if (error && error["first_name"]) {
                  dispatch(resetError("first_name"));
                }
              }}
            />
          </Form.Item>
          <Form.Item
            className="createPatientModal-cont__form__formItemWrapper__formItem"
            name="last_name"
            rules={[
              {
                required: true,
                message: "Please enter your Last Name",
              },
            ]}
            label={
              <Typography.Text
                className="createPatientModal-cont__form__formItemWrapper__formItem__text"
                // style={{
                //   fontFamily: `${token?.Typography?.fontFamily}`,
                // }}
              >
                Last Name
              </Typography.Text>
            }
            colon={false}>
            <Input
              type="text"
              placeholder="Last Name"
              onChange={() => {
                if (error && error["last_name"]) {
                  dispatch(resetError("last_name"));
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="createPatientModal-cont__form__formItemWrapper">
          <Form.Item
            name="phone_number"
            rules={[
              {
                required: true,
                message: "Please enter your Phone Number",
              },
            ]}
            className="createPatientModal-cont__form__formItemWrapper__formItem"
            label={
              <Typography.Text className="createPatientModal-cont__form__formItemWrapper__formItem__text">
                Phone Number
              </Typography.Text>
            }
            colon={false}>
            <PhoneNumberInput
              onChange={() => {
                if (error && error["phone_number"]) {
                  dispatch(resetError("phone_number"));
                }
              }}
            />
          </Form.Item>
          <Form.Item
            className="createPatientModal-cont__form__formItemWrapper__formItem"
            name="email"
            style={{
              marginTop: width > 600 ? "5px" : null,
            }}
            rules={[
              // {
              //   required: true,
              //   message: "Please enter your Email",
              // },
              // for custom validation
              {
                validator: emailValidator,
              },
            ]}
            label={
              <Typography.Text
                className="createPatientModal-cont__form__formItemWrapper__formItem__text"
                // style={{
                //   fontFamily: `${token?.Typography?.fontFamily}`,
                // }}
              >
                Email
              </Typography.Text>
            }
            colon={false}>
            <Input
              type="text"
              placeholder="Email"
              onChange={() => {
                if (error && error["email"]) {
                  dispatch(resetError("email"));
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="createPatientModal-cont__form__formItemWrapper">
          <Form.Item
            className="createPatientModal-cont__form__formItemWrapper__formItem"
            name="patient_id"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter your ID code",
            //   },
            // ]}
            label={
              <Typography.Text
                className="createPatientModal-cont__form__formItemWrapper__formItem__text"
                // style={{
                //   fontFamily: `${token?.Typography?.fontFamily}`,
                // }}
              >
                ID
              </Typography.Text>
            }
            colon={false}>
            <Input
              type="text"
              placeholder="ID"
              onChange={() => {
                if (error && error["patient_id"]) {
                  dispatch(resetError("patient_id"));
                }
              }}
            />
          </Form.Item>
          <Form.Item
            className="createPatientModal-cont__form__formItemWrapper__formItem"
            name="patient_id_exp_date"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter your Expiration Date",
            //   },
            // ]}
            label={
              <Typography.Text
                className="createPatientModal-cont__form__formItemWrapper__formItem__text"
                // style={{
                //   fontFamily: `${token?.Typography?.fontFamily}`,
                // }}
              >
                Expiration Date
              </Typography.Text>
            }
            colon={false}>
            <DatePicker
              className="createPatientModal-cont__form__formItemWrapper__formItem__datePicker"
              format={dateFormat}
              allowClear={true}
              onChange={() => {
                if (error && error["patient_id_exp_date"]) {
                  dispatch(resetError("patient_id_exp_date"));
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="createPatientModal-cont__form__formItemWrapper__uploadwrap">
          <Form.Item
            className="createPatientModal-cont__form__formItemWrapper__uploadwrap__formItem"
            name="id_image_url"
            // valuePropName="idImage"
            // getValueFromEvent={(event) => {
            //   return event?.idImage;
            // }}
          >
            <div>
              <Upload
                className="createPatientModal-cont__form__formItemWrapper__uploadwrap__formItem__upload"
                style={{
                  width: "100%",
                }}
                customRequest={postImage}
                maxCount={1}
                showUploadList={imageUrl ? true : false}
                accept="image/png, image/jpeg"
                name="image"
                // onChange={handleIdImageChange}
                fileList={imageUrl ? [imageUrl] : []}
                onRemove={onImageRemove}
                onChange={handleIdImageChange}>
                {imageUrl || progress > 0 ? null : (
                  <Button
                    className="createPatientModal-cont__form__formItemWrapper__uploadwrap__formItem__upload__btn"
                    type="default"
                    size="small"
                    block
                    icon={<UploadOutlined />}>
                    Upload ID Picture
                  </Button>
                )}
              </Upload>
              {progress > 0 ? <Progress percent={progress} /> : null}
            </div>
          </Form.Item>
        </div>
        <Divider
          type="horizontal"
          className="createPatientModal-cont__form__divider"
        />
        <div className="createPatientModal-cont__form__formItemWrapper">
          <Form.Item
            className="createPatientModal-cont__form__formItemWrapper__formItem"
            name="medical_recommendation"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter your Med Rec",
            //   },
            // ]}
            label={
              <Typography.Text
                className="createPatientModal-cont__form__formItemWrapper__formItem__text"
                // style={{
                //   fontFamily: `${token?.Typography?.fontFamily}`,
                // }}
              >
                Med Rec
              </Typography.Text>
            }
            colon={false}>
            <Input
              type="text"
              placeholder="Med Rec"
              onChange={() => {
                if (error && error["medical_recommendation"]) {
                  dispatch(resetError("medical_recommendation"));
                }
              }}
            />
          </Form.Item>
          <Form.Item
            className="createPatientModal-cont__form__formItemWrapper__formItem"
            name="rec_exp_date"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter your Med Expiration Date",
            //   },
            // ]}
            label={
              <Typography.Text
                className="createPatientModal-cont__form__formItemWrapper__formItem__text"
                // style={{
                //   fontFamily: `${token?.Typography?.fontFamily}`,
                // }}
              >
                Expiration Date
              </Typography.Text>
            }
            colon={false}>
            <DatePicker
              className="createPatientModal-cont__form__formItemWrapper__formItem__datePicker"
              format={dateFormat}
              allowClear={true}
              onChange={() => {
                if (error && error["rec_exp_date"]) {
                  dispatch(resetError("rec_exp_date"));
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="createPatientModal-cont__form__formItemWrapper__uploadwrap">
          <Form.Item
            className="createPatientModal-cont__form__formItemWrapper__uploadwrap__formItem"
            name="med_rec_image_url"
            // valuePropName="medIMage"
            // getValueFromEvent={(event) => {
            //   return event?.medIMage;
            // }}
            // rules={[
            //   {
            //     required: true,
            //     validator: uploadImageValidator,
            //   },
            // ]}
          >
            <div>
              <Upload
                className="createPatientModal-cont__form__formItemWrapper__uploadwrap__formItem__upload"
                style={{
                  width: "100%",
                }}
                customRequest={postMedImage}
                maxCount={1}
                showUploadList={medImageUrl ? true : false}
                accept="image/png, image/jpeg"
                name="medimage"
                onChange={handlemedImageChange}
                fileList={medImageUrl ? [medImageUrl] : []}
                onRemove={onMedImageRemove}>
                {medImageUrl || medProgress > 0 ? null : (
                  <Button
                    className="createPatientModal-cont__form__formItemWrapper__uploadwrap__formItem__upload__btn"
                    type="default"
                    size="small"
                    block
                    icon={<UploadOutlined />}>
                    Upload Med Rec Picture
                  </Button>
                )}
              </Upload>
              {medProgress > 0 ? <Progress percent={medProgress} /> : null}
            </div>
          </Form.Item>
        </div>
        <div className="createPatientModal-cont__form__formItemWrapper">
          <Form.Item
            className="createPatientModal-cont__form__formItemWrapper__formItem"
            name="dob"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter your  Date Of Birth",
            //   },
            // ]}
            label={
              <Typography.Text
                className="createPatientModal-cont__form__formItemWrapper__formItem__text"
                // style={{
                //   fontFamily: `${token?.Typography?.fontFamily}`,
                // }}
              >
                Date Of Birth
              </Typography.Text>
            }
            colon={false}>
            <DatePicker
              className="createPatientModal-cont__form__formItemWrapper__formItem__datePicker"
              format={dateFormat}
              // defaultValue={dayjs('2015-06', 'YYYY-MM-dd')}
              allowClear={true}
              onChange={() => {
                if (error && error["dob"]) {
                  dispatch(resetError("dob"));
                }
              }}
            />
          </Form.Item>
          <Form.Item
            className="createPatientModal-cont__form__formItemWrapper__formItem"
            name="state"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter your State",
            //   },
            // ]}
            label={
              <Typography.Text
                className="createPatientModal-cont__form__formItemWrapper__formItem__text"
                // style={{
                //   fontFamily: `${token?.Typography?.fontFamily}`,
                // }}
              >
                State
              </Typography.Text>
            }
            colon={false}>
            <Input
              type="text"
              placeholder="State"
              onChange={() => {
                if (error && error["state"]) {
                  dispatch(resetError("state"));
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="createPatientModal-cont__form__formItemWrapper">
          <Form.Item
            className="createPatientModal-cont__form__formItemWrapper__formItemAddress"
            name="address"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter your Address",
            //   },
            // ]}
            label={
              <Typography.Text
                className="createPatientModal-cont__form__formItemWrapper__formItem__text"
                // style={{
                //   fontFamily: `${token?.Typography?.fontFamily}`,
                // }}
              >
                Address
              </Typography.Text>
            }
            colon={false}>
            <Input
              type="text"
              placeholder="Address"
              onChange={() => {
                if (error && error["address"]) {
                  dispatch(resetError("address"));
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="createPatientModal-cont__form__formItemWrapper">
          <Form.Item
            className="createPatientModal-cont__form__formItemWrapper__formItem"
            name="city"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter your City",
            //   },
            // ]}
            label={
              <Typography.Text
                className="createPatientModal-cont__form__formItemWrapper__formItem__text"
                // style={{
                //   fontFamily: `${token?.Typography?.fontFamily}`,
                // }}
              >
                City
              </Typography.Text>
            }
            colon={false}>
            <Input
              type="text"
              placeholder="City"
              onChange={() => {
                if (error && error["city"]) {
                  dispatch(resetError("city"));
                }
              }}
            />
          </Form.Item>
          <Form.Item
            className="createPatientModal-cont__form__formItemWrapper__formItem"
            name="zipcode"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter your Zip Code",
            //   },
            //   {
            //     validator: zipValidator,
            //   },
            // ]}
            label={
              <Typography.Text
                className="createPatientModal-cont__form__formItemWrapper__formItem__text"
                // style={{
                //   fontFamily: `${token?.Typography?.fontFamily}`,
                // }}
              >
                Zip Code
              </Typography.Text>
            }
            colon={false}>
            <Input
              type="text"
              placeholder="Zip Code"
              onChange={() => {
                if (error && error["zipcode"]) {
                  dispatch(resetError("zipcode"));
                }
              }}
            />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
}
