import React from "react";
import Listings from "./Listings/Listings";

const PromoCodes = React.lazy(() => import("./PromoCodes/PromoCodes"));
const Patient = React.lazy(() => import("./Patient/Patient"));
const Settings = React.lazy(() => import("./Settings/Settings"));
const MyAccount = React.lazy(() => import("./MyAccount/MyAccount"));
const Team = React.lazy(() => import("./Team/Team"));
const Menu = React.lazy(() => import("./Menu/Menu"));
const MenuDashBoard = React.lazy(() => import("./MenuDashBoard/MenuDashBoard"));
const Reports = React.lazy(() => import("./Reports/Reports"));
const Orders = React.lazy(() => import("./Orders/Orders"));
const MapContainer = React.lazy(() => import("./MapBox/MapContainer"));
const Metrics = React.lazy(() => import("./Metrics/Metrics"));
const GeneratedReports = React.lazy(() =>
  import("./GeneratedReports/GeneratedReports")
);
const Terms = React.lazy(() => import("./Terms/Terms"));
const Privacy = React.lazy(() => import("./Privacy/Privacy"));
const Safe = React.lazy(() => import("./Safe/Safe"));
const SingleBlog = React.lazy(() => import("./SingleBlog/SingleBlog"));
const BlogArchive = React.lazy(() => import("./BlogArchive/BlogArchive"));
const Landing = React.lazy(() => import("./Landing/Landing"));
const MainLanding = React.lazy(() => import("./MainLanding/MainLanding"));
const ResetPassword = React.lazy(() => import("./ResetPassword/ResetPassword"));

export {
  MenuDashBoard,
  Team,
  Listings,
  Orders,
  Patient,
  Reports,
  Settings,
  Menu,
  Safe,
  MyAccount,
  Landing,
  BlogArchive,
  SingleBlog,
  MainLanding,
  Metrics,
  Terms,
  Privacy,
  MapContainer,
  GeneratedReports,
  PromoCodes,
  ResetPassword,
};
