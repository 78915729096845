import React, { useEffect } from "react";
import "./EditSubmenuModal.scss";
import { Typography, Modal, Input, Form, Checkbox } from "antd";
import useMenuModal from "../../hooks/useMenuModal";
import { useParams } from "react-router";
import { resetErrors } from "../../features/menu/menuSlice";
import { get_possible_sub_menus } from "../../features/menu/menuActions";

export default function EditSubmenuModal({
  isOpened,
  title,
  subTitle,
  //   onOk,
  onCancel,
  targetKey,
  afterResponse,
  subMenu,
}) {
  const {
    modal,
    button,
    formRef,
    error,
    loading,
    dispatch,
    editSubMenu,
    formData,
    setFormData,
    isChecked,
    onChackboxChange,
    setIsChecked,
  } = useMenuModal();

  const { menuId, subMenuId } = useParams();

  const edit = (menuId, id, text, external_id, isMetrc_integrated) => {
    return dispatch(
      editSubMenu({
        menuId,
        id,
        text,
        ...(subMenu?.external_id === external_id ? {} : { external_id }),
        sync_with_metrc: isMetrc_integrated,
      })
    );
  };

  const onOk = async (menuId, id, text, external_id, isMetrc_integrated) => {
    const data = await edit(menuId, id, text, external_id, isMetrc_integrated);

    if (data.type === "editsubmenu/fulfilled") {
      onCancel();
      dispatch(get_possible_sub_menus());
      formRef?.current.setFields([
        {
          name: "name",
          value: "",
          errors: [],
        },
      ]);
      setIsChecked(false);
    }
  };

  useEffect(() => {
    if (subMenu) {
      setFormData([
        {
          name: "name",
          value: subMenu?.name,
        },
        {
          name: "external_id",
          value: subMenu?.external_id,
        },
      ]);
      setIsChecked(subMenu?.sync_with_metrc);
    }
  }, [isOpened]);

  useEffect(() => {
    if (error && !loading) {
      for (let key in error) {
        formRef?.current?.setFields([
          {
            name: key,
            errors: [error[key][0]],
          },
        ]);
      }
    }
  }, [loading, error, isOpened]);

  return (
    <Modal
      open={isOpened}
      title={title}
      onOk={() => {
        formRef.current.submit();
      }}
      okText="Save"
      closeIcon={false}
      centered
      onCancel={() => {
        onCancel();
        dispatch(resetErrors());
        formRef?.current?.setFields([
          {
            name: "name",
            value: "",
            errors: [],
          },
        ]);
        setIsChecked(false);
      }}
      style={{
        borderTop: `2px solid ${button?.colorPrimary}`,
      }}
      className="editSubmenuModal-cont"
      cancelButtonProps={{
        className: "editSubmenuModal-cont__btn",
        size: "small",
      }}
      okButtonProps={{
        size: "small",
        htmlType: "submit",
        type: "primary",
        className: "editSubmenuModal-cont__btn",
      }}>
      <Form
        layout="vertical"
        ref={formRef}
        className="editSubmenuModal-cont__form"
        onFinish={(values) => {
          onOk(
            menuId,
            subMenuId,
            values["name"],
            values["external_id"],
            isChecked
          );
        }}
        onFinishFailed={(error) => {}}
        fields={formData}>
        <Form.Item
          name="name"
          className="editSubmenuModal-cont__form__formItem"
          rules={[
            {
              required: true,
              message: "Name is required",
            },
          ]}
          colon={false}
          label={
            <Typography.Text
              className="editSubmenuModal-cont__form__formItem__text"
              style={{
                color: `${modal.titleColor}`,
              }}>
              {subTitle[0]}
            </Typography.Text>
          }>
          <Input type="text" placeholder="Name" />
        </Form.Item>
        {subTitle[1] ? (
          <Form.Item
            name="external_id"
            className="editSubmenuModal-cont__form__formItem"
            rules={[
              {
                required: true,
                message: "External id is required",
              },
            ]}
            colon={false}
            label={
              <Typography.Text
                className="editSubmenuModal-cont__form__formItem__text"
                style={{
                  color: `${modal.titleColor}`,
                }}>
                {subTitle[1]}
              </Typography.Text>
            }>
            <Input type="text" />
          </Form.Item>
        ) : null}
        <Checkbox onChange={onChackboxChange} checked={isChecked}>
          METRC Integrated
        </Checkbox>
      </Form>
    </Modal>
  );
}
