import { theme, message } from "antd";
import { useEffect, useRef, useState } from "react";
import Utils from "../utils";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { resetError } from "../features/patient/patientSlice";
import axiosClient from "../common/api/request";
import config from "../config";
import useWindowWidth from "./useWindowWidth";
const { emailValidator, getFormattedDate } = Utils;
function generateUniqueId() {
  const timestamp = Date.now().toString(36); // Convert timestamp to base36 string
  const randomString = Math.random().toString(36).substr(2, 5); // Random string

  return `${timestamp}-${randomString}`;
}
export default function useCreatePatientModal(isOpened, patient, onCancel) {
  const { useToken } = theme;
  const { token } = useToken();
  const { width } = useWindowWidth();
  const formRef = useRef(null);
  const { loading, error, success } = useSelector((state) => state?.patient);
  const dispatch = useDispatch();
  // const [phoneCode, setPhoneCode] = useState("+1");
  const dateFormat = "YYYY-MM-DD";
  const [formData, setFormData] = useState([]);
  // const phoneCodeValidator = (_, value) => {
  //   return new Promise((resolve, reject) => {
  //     if (!value && !phoneCode) {
  //       reject("Please enter your valid Number");
  //     } else if (!phoneCode && value) {
  //       reject("please enter Phone code");
  //     } else if (!/^\d+$/.test(value)) {
  //       reject("Phone Number must be a whole number.");
  //     } else {
  //       resolve();
  //     }
  //   });
  // };
  // const selectBefore = (
  //   <Select
  //     value={phoneCode ? phoneCode : "Code"}
  //     style={{
  //       width: 72,
  //     }}
  //     onChange={(code) => {
  //       formRef.current.validateFields(["phone_number"]);
  //       setPhoneCode(code);
  //       if (error && error["phone_number"]) {
  //         dispatch(resetError("phone_number"));
  //       }
  //     }}
  //     options={[1].map((el) => ({
  //       value: `+${el}`,
  //       label: `+${el}`,
  //     }))}
  //   />
  // );
  const [imageUrl, setImageUrl] = useState(null);
  const [progress, setProgress] = useState(0);
  const [medImageUrl, setMedImageUrl] = useState(null);
  const [medProgress, setmedProgress] = useState(0);

  const postImage = async (formData) => {
    const { onSuccess, onError, file, onProgress } = formData;
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
    }
    const newFormData = new FormData();
    newFormData.append("image", file);
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/images/`,
        newFormData,
        {
          config,
          onUploadProgress: (event) => {
            const percent = Math.floor((event.loaded / event.total) * 100);
            setProgress(percent);
            if (percent === 100) {
              setTimeout(() => setProgress(0), 1000);
            }
            onProgress({ percent: (event.loaded / event.total) * 100 });
          },
        }
      );
      setImageUrl({
        status: "done",
        id: data?.id,
        url: data?.image,
        uid: file?.uid,
        name: file?.name,
      });
      onSuccess("Ok");
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        const errorKeys = Object.keys(error.response.data);
        const firstErrorKey = errorKeys.length > 0 ? errorKeys[0] : null;
        const errorMessage = firstErrorKey
          ? error.response.data[firstErrorKey][0]
          : "An error occurred.";
        formRef?.current?.setFields([
          {
            name: "id_image_url",
            errors: [errorMessage],
          },
        ]);
        onError({ errorMessage });
      } else {
        const messsage = error?.message;
        onError({ message });
        formRef?.current?.setFields([
          {
            name: "id_image_url",
            errors: [message],
          },
        ]);
      }
    }
  };
  const postMedImage = async (formData) => {
    const { onSuccess, onError, file, onProgress } = formData;
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
    }
    const newFormData = new FormData();
    newFormData.append("image", file);
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/images/`,
        newFormData,
        {
          config,
          onUploadProgress: (event) => {
            const percent = Math.floor((event.loaded / event.total) * 100);
            setmedProgress(percent);
            if (percent === 100) {
              setTimeout(() => setmedProgress(0), 1000);
            }
            onProgress({ percent: (event.loaded / event.total) * 100 });
          },
        }
      );
      setMedImageUrl({
        status: "done",
        id: data?.id,
        url: data?.image,
        uid: file?.uid,
        name: file?.name,
      });
      onSuccess("Ok");
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        const errorKeys = Object.keys(error.response.data);
        const firstErrorKey = errorKeys.length > 0 ? errorKeys[0] : null;
        const errorMessage = firstErrorKey
          ? error.response.data[firstErrorKey][0]
          : "An error occurred.";
        formRef?.current?.setFields([
          {
            name: "id_image_url",
            errors: [errorMessage],
          },
        ]);
        onError({ errorMessage });
      } else {
        const messsage = error?.message;
        onError({ message });
        formRef?.current?.setFields([
          {
            name: "med_rec_image_url",
            errors: [message],
          },
        ]);
      }
    }
  };
  const handleIdImageChange = (info) => {
    if (info.file.status === "uploading") {
      setImageUrl({
        uid: info?.file?.originFileObj?.uid,
        name: info?.file?.originFileObj?.name,
        status: info?.file?.status,
      });
    }
  };
  const onImageRemove = () => {
    setImageUrl(null);
  };

  const handlemedImageChange = (info) => {
    if (info.file.status === "uploading") {
      setMedImageUrl({
        uid: info?.file?.originFileObj?.uid,
        name: info?.file?.originFileObj?.name,
        status: info?.file?.status,
      });
    }
  };
  const onMedImageRemove = () => {
    setMedImageUrl(null);
  };

  useEffect(() => {
    if (patient) {
      setFormData([
        {
          name: "first_name",
          value: patient?.first_name ? patient?.first_name : "",
        },
        {
          name: "last_name",
          value: patient?.last_name ? patient?.last_name : "",
        },
        {
          name: "phone_number",
          value: patient?.phone_number ? patient?.phone_number : "",
        },
        {
          name: "email",
          value: patient?.email ? patient?.email : "",
        },
        {
          name: "patient_id",
          value: patient?.patient_id ? patient?.patient_id : "",
        },
        {
          name: "patient_id_exp_date",
          value: patient?.patient_id_exp_date
            ? dayjs(patient?.patient_id_exp_date)
            : "",
        },
        {
          name: "medical_recommendation",
          value: patient?.medical_recommendation
            ? patient?.medical_recommendation?.text
            : "",
        },
        {
          name: "rec_exp_date",
          value: patient?.rec_exp_date ? dayjs(patient?.rec_exp_date) : "",
        },
        {
          name: "dob",
          value: patient?.dob ? dayjs(patient?.dob) : "",
        },
        {
          name: "state",
          value: patient?.state ? patient?.state : "",
        },
        {
          name: "address",
          value: patient?.address ? patient?.address : "",
        },
        {
          name: "city",
          value: patient?.city ? patient?.city : "",
        },
        {
          name: "zipcode",
          value: patient?.zipcode ? patient?.zipcode : "",
        },
      ]);
      // setPhoneCode(patient?.phone_number?.slice(0, 2));
      setMedImageUrl(
        patient?.med_rec_image_url
          ? {
              url: patient?.med_rec_image_url,
              name: patient?.med_rec_image_url,
              uid: generateUniqueId(),
            }
          : null
      );
      setImageUrl(
        patient?.id_image_url
          ? {
              url: patient?.id_image_url,
              name: patient?.id_image_url,
              uid: generateUniqueId(),
            }
          : null
      );
    }
  }, [isOpened]);

  useEffect(() => {
    if (isOpened) {
      if (error && !loading) {
        for (let key in error) {
          formRef?.current?.setFields([
            {
              name: key, // Field name you want to set the error for
              errors: [error[key][0]], // Assuming your error has a 'message' field
            },
          ]);
        }
      }
    }
  }, [error, loading]);

  useEffect(() => {
    if (isOpened) {
      if (success) {
        onCancel();
        dispatch(resetError());
        formRef.current.resetFields();
        onCancel();
        setImageUrl(null);
        setMedImageUrl(null);
        setFormData([]);
      }
    }
  }, [success]);

  return {
    token,
    formRef,
    dateFormat,
    width,
    setImageUrl,
    onImageRemove,
    imageUrl,
    emailValidator,
    getFormattedDate,
    medImageUrl,
    onMedImageRemove,
    formData,
    setFormData,
    dispatch,
    resetError,
    loading,
    error,
    postImage,
    progress,
    postMedImage,
    medProgress,
    setMedImageUrl,
    handleIdImageChange,
    handlemedImageChange,
  };
}
