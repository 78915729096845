import React, { useState, useEffect, useRef } from "react";
import {
  theme,
  Tag,
  Dropdown,
  Spin,
  Typography,
  Button,
  notification,
} from "antd";
import {
  DownOutlined,
  EditOutlined,
  DollarTwoTone,
  FileTextTwoTone,
  FundTwoTone,
  // ArrowUpOutlined,
  EnvironmentTwoTone,
  GiftFilled,
} from "@ant-design/icons";
import useWindowWidth from "./useWindowWidth";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import Utils from "../utils";
import {
  getItems,
  updateOrderPaymentType,
  updateOrderStatus,
  updateOrderDriver,
  getRoomOrders,
  reportToMetric,
} from "../features/orders/ordersActions";
import {
  removeSocketOrders,
  // resetError
} from "../features/orders/ordersSlice";
import { getCardsReports } from "../features/report/reportAction";
import { useLocation } from "react-router";
import LocalStorageService from "../common/services/LocalStorageService";
import Can from "../common/services/permissions/Can";

export default function useOrderData(
  setPage,
  patient,
  setPerPage,
  page,
  perPage,
  searchVal,
  date_start,
  date_end,
  orderBy,
  setOrderBy,
  driverFilter,
  setDriverFilter,
  locationFilter,
  setLocationFilter,
  paymentFilter,
  setPaymentFilter,
  selectedSegment,
  setSelectedSegment,
  segments,
  tabKey,
  isByMetrc,
) {
  const { useToken } = theme;
  const { token } = useToken();
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, title, text) => {
    api[type]({
      message: title,
      description: text,
      placement: "top",
    });
  };
  const location = useLocation();
  const dispatch = useDispatch();
  const { results: serverResults, meta } = useSelector(
    (state) => state?.orders?.items
  );
  const count = meta?.count;
  const { loading, error, success, socketItems, roomLoading } = useSelector(
    (state) => state?.orders
  );
  const results =
    page === 1 && searchVal === "" && socketItems && serverResults
      ? getUniqueListBy([...socketItems, ...serverResults], "id")
      : serverResults;
  const drivers = useSelector((state) => state?.driver?.drivers?.results);
  const { options } = useSelector((state) => state?.paymentTypes);
  const { possibleLocations } = useSelector((state) => state?.locations);
  const { result: reports, loading: reportLoading } = useSelector(
    (state) => state?.cardReports
  );
  const { width, isSmallDesktop, isMiddlesDesktop, isMobile } =
    useWindowWidth();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const [currentField, setCurrentField] = useState("");
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [scrollwidth, setScrollWidth] = useState("1550");
  const tableRef = useRef();

  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  const totalItems = [
    {
      title: "Total Earned",
      icon: (
        <DollarTwoTone
          twoToneColor={token?.icons?.baseColor}
          style={{
            fontSize: width <= 1240 && width > 900 ? "16px" : "20px",
          }}
        />
      ),
      bodyText: Utils?.isValidNumber(reports?.total_earned)
        ? `${Utils?.roundNumber(reports?.total_earned)}`
        : "$0.00",
      id: "totalText1",
    },
    {
      title: "Total Orders Count",
      icon: (
        <FileTextTwoTone
          twoToneColor={token?.icons?.baseColor}
          style={{
            fontSize: width <= 1240 && width > 900 ? "16px" : "20px",
          }}
        />
      ),
      bodyText: Utils?.isValidNumber(reports?.total_orders_count) ? Math?.round(reports?.total_orders_count) : "0",
      id: "totalText2",
    },
    {
      title: "Total Gross Amount",
      // month: "July",
      icon: (
        <FundTwoTone
          twoToneColor={token?.icons?.baseColor}
          style={{
            fontSize: width <= 1240 && width > 900 ? "16px" : "20px",
          }}
        />
      ),
      bodyText: Utils?.isValidNumber(reports?.total_gross_amount)
        ? `${Utils?.roundNumber(reports?.total_gross_amount)}`
        : "$0.00",
      // bodyIcon: (
      //   <ArrowUpOutlined
      //     style={{
      //       color: token?.icons?.baseColor,
      //       fontSize: width <= 1240 && width > 900 ? "16px" : "20px",
      //     }}
      //   />
      // ),
      id: "totalText3",
    },
    {
      title: "Top Region",
      icon: (
        <EnvironmentTwoTone
          twoToneColor={token?.icons?.baseColor}
          style={{
            fontSize: width <= 1240 && width > 900 ? "16px" : "20px",
          }}
        />
      ),
      bodyText: reports?.top_region ? reports?.top_region : "",
      id: "totalText4",
    },
  ];

  const statusMapping = (status) => {
    switch (status) {
      case "IN_PROGRESS":
        return "gold";
      case "READY_FOR_ATTAINMENT":
        return "gold";
      case "COMPLETE":
        return "green";
      case "CANCELED_CUSTOMER":
        return "red";
      default:
        return "gold";
    }
  };

  const ammendOrderDriver = (params) => {
    dispatch(updateOrderDriver(params));
  };

  const [dropdownItemsCards, setDropdownItemsCards] = useState([]);

  const dropdownItemsActions = [
    {
      key: "IN_PROGRESS",
      label: Utils?.statusNameMapping("IN_PROGRESS"),
    },
    {
      key: "READY_FOR_ATTAINMENT",
      label: Utils?.statusNameMapping("READY_FOR_ATTAINMENT"),
    },
    {
      key: "COMPLETE",
      label: Utils?.statusNameMapping("COMPLETE"),
    },
    {
      key: "CANCELED_CUSTOMER",
      label: Utils?.statusNameMapping("CANCELED_CUSTOMER"),
    },
  ];

  const tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      className: classNames("table-row-text", {
        "smallTable-row-text":
          isSmallDesktop || isMiddlesDesktop || width <= 1630,
      }),
      width: 100,
      selected: true,
      sorter: true,
      onCell: (record) => {
        return {
          style: {
            cursor: "pointer",
            color: token?.colorPrimary,
          },
          onClick: () => {
            if (
              Can({
                I: "change",
                a: "order",
                fallback: false,
                children: true,
              })
            ) {
              const order = results.find((el) => el?.id === record?.key);
              setSelectedOrder(order);
              setIsDrawerOpened(true);
            }
          },
        };
      },
      render: (_, record, a) => {
        const patientCount = count - ((page - 1) * perPage + a);
        const orderCount = patient && Number(patient) === record?.customer.id ? patientCount : record?.customer?.orders_count;
        if (orderCount > 0)
          return orderCount === 1 ? (
            <div
              className="table-first-orderCont"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "0px",
              }}>
              <Typography.Text
                style={{
                  color: token?.colorPrimary,
                }}>
                {record?.key}
              </Typography.Text>
              <Typography.Text
                style={{
                  color: token?.colorSecondaryLogo,
                }}>
                {`${orderCount}st Order`}
              </Typography.Text>
            </div>
          ) : (
            <div
              className="table-first-orderCont"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "0px",
              }}>
              <Typography.Text
                style={{
                  color: token?.colorPrimary,
                }}>
                {record?.key}
              </Typography.Text>
              <Typography.Text
                style={{
                  color: token?.colorPrimary,
                }}>
                {orderCount === 2
                  ? `${orderCount}nd Order`
                  : `${orderCount}th Order`}
              </Typography.Text>
            </div>
          );
      },
    },
    {
      title: "Ordered At",
      dataIndex: "date_ordered",
      key: "date_ordered",
      className: "table-row-text",
      selected: true,
      sorter: true,
      className: classNames("table-row-text", {
        "smallTable-row-text":
          isSmallDesktop || isMiddlesDesktop || width <= 1630,
      }),
      width: 120,
      render: (_, record) => {
        const dateTime = Utils?.getFormattedDateTime(
          record?.date_ordered
        )?.split(" ");
        const date = dateTime[0];
        const time = Utils?.convertToAmPm(dateTime[1]);
        return date + " " + time;
      },
      // ellipsis:true
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
      className: "table-row-text",
      selected: isMobile ? false : true,
      render: (_, record) => {
        return record.created_by?.username;
      },
      ellipsis: true,
      width: 230,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      className: classNames("table-row-text", {
        "smallTable-row-text":
          isSmallDesktop || isMiddlesDesktop || width <= 1630,
      }),
      width: 120,
      selected: true,
      render: (_, record) => {
        return record?.total;
      },
    },
    {
      title: "Patient",
      dataIndex: "patient",
      key: "patient",
      className: "table-row-text",
      selected: isMobile ? false : true,
      render: (_, record) => {
        const today = Utils?.getFormattedDate(new Date())?.split(".");
        const dob = record?.customer?.dob?.split("-");
        return dob && today[0] === dob[1] && today[1] === dob[2] ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "0px",
            }}>
            <Typography.Text
              style={{
                color: token?.colorPrimary,
              }}>
              {record?.customer?.first_name + " " + record?.customer?.last_name}
            </Typography.Text>
            <Typography.Text
              style={{
                color: token?.colorPrimary,
              }}>
              {"It's a patient's birthday "} <GiftFilled />
            </Typography.Text>
          </div>
        ) : (
          record?.customer?.first_name + " " + record?.customer?.last_name
        );
      },
      width: 200,
    },
    // {
    //   title: "Order Count",
    //   dataIndex: "order_count",
    //   key: "order_count",
    //   className: classNames("table-row-text", {
    //     "smallTable-row-text":
    //       isSmallDesktop || isMiddlesDesktop || width <= 1630,
    //   }),
    //   selected: false,
    //   render: (_, record) => {
    //     return record?.customer?.orders_count;
    //   },
    //   onCell: (record) => {
    //     return {
    //       style: {
    //         color:record?.customer?.orders_count===1? token?.colorPrimary:null
    //       },
    //     };
    //   },
    //   width: 100,
    // },
    {
      title: "Driver",
      dataIndex: "driver",
      key: "driver",
      className: "table-row-text",
      selected: true,
      width: 250,
      filters: drivers?.map((driver) => ({
        text: `${driver?.first_name} ${driver?.last_name}`,
        value: driver?.id,
      })),
      filterSearch: true,
      // filterOnClose:false,
      render: (_, record) => {
        const foundDriver = drivers?.find(
          (driver) => driver?.id === record?.driver
        );
        return loading &&
          currentField === "Driver" &&
          selectedRowKey === record?.key ? (
          <Spin />
        ) : (
          <Can
            I="change"
            a="order"
            fallback={
              <div className="orders-cont__content__pageCont__dropdownDriver__item">
                <Typography.Text
                  className="orders-cont__content__pageCont__dropdownDriver__item__text"
                  style={{
                    color: !foundDriver
                      ? `${token?.Typography?.baseColorText}`
                      : null,
                  }}>
                  {/* {orderStatus ? orderStatus : "Unassigned"} */}
                  {foundDriver
                    ? `${foundDriver?.first_name} ${foundDriver?.last_name}`
                    : ""}
                </Typography.Text>
              </div>
            }>
            <Dropdown
              className="orders-cont__content__pageCont__dropdownDriver"
              trigger={["click"]}
              menu={{
                items: drivers?.map((driver) => ({
                  key: driver?.id,
                  label: `${driver?.first_name} ${driver?.last_name}`,
                })),
                onClick: ({ key }) => {
                  setSelectedRowKey(record?.key);
                  setCurrentField("Driver");
                  ammendOrderDriver({
                    id: record?.key,
                    driver: key,
                  });
                },
              }}
              overlayStyle={{
                maxHeight: "200px",
                overflowY: "auto",
                maxWidth: "233px",
              }}>
              <div className="orders-cont__content__pageCont__dropdownDriver__item">
                <Typography.Text
                  className="orders-cont__content__pageCont__dropdownDriver__item__text"
                  style={{
                    color: !foundDriver
                      ? `${token?.Typography?.baseColorText}`
                      : null,
                  }}>
                  {/* {orderStatus ? orderStatus : "Unassigned"} */}
                  {foundDriver
                    ? `${foundDriver?.first_name} ${foundDriver?.last_name}`
                    : "Select Driver"}
                </Typography.Text>
                <DownOutlined
                  style={{
                    color: !foundDriver ? `${token?.icons?.baseColor}` : null,
                  }}
                />
              </div>
            </Dropdown>
          </Can>
        );
      },
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   className: classNames("table-row-text", {
    //     "smallTable-row-text":
    //       isSmallDesktop || isMiddlesDesktop || width <= 1630,
    //   }),
    //   width: 110,
    //   selected: isMobile ? false : true,
    //   render: (_, record) => {
    //     return (
    //       <Tag color={statusMapping(record?.status)}>
    //         {statusNameMapping(record?.status)}
    //       </Tag>
    //     );
    //   },
    // },
    {
      title: "Payment Types",
      dataIndex: "payment_type",
      key: "payment_types",
      selected: true,
      className: classNames("table-row-text", {
        "smallTable-row-text":
          isSmallDesktop || isMiddlesDesktop || width <= 1630,
      }),
      width: 130,
      filters: options?.map((option) => ({
        text: option?.label,
        value: option?.key,
      })),
      filterSearch: true,
      render: (_, record) => {
        return loading &&
          currentField === "Cart" &&
          selectedRowKey === record?.key ? (
          <Spin />
        ) : (
          <Can
            I="change"
            a="order"
            fallback={
              <div className="order-drawer__tottal-cont__options__wrapper__dropdownCard__item">
                <Typography.Text
                  className="order-drawer__tottal-cont__options__wrapper__dropdownCard__item__text"
                  style={{
                    color: `${token?.Typography?.baseColorText}`,
                  }}>
                  {record?.payment_type
                    ? options?.find(
                      (payment) => payment?.key === record?.payment_type
                    )?.label
                    : ""}
                </Typography.Text>
              </div>
            }>
            <Dropdown
              className="order-drawer__tottal-cont__options__wrapper__dropdownCard"
              trigger={["click"]}
              menu={{
                items: dropdownItemsCards,
                onClick: ({ key }) => {
                  const faoundPaymentType = dropdownItemsCards.find(
                    (el) => key === el?.key
                  );
                  setCurrentField("Cart");
                  setSelectedRowKey(record?.key);
                  amendPaymentType({
                    payment_type: faoundPaymentType?.key,
                    id: record?.key,
                  });
                },
              }}
              overlayStyle={{
                maxHeight: "200px",
                overflowY: "auto",
                maxWidth: "230px",
              }}>
              <div className="order-drawer__tottal-cont__options__wrapper__dropdownCard__item">
                <Typography.Text
                  className="order-drawer__tottal-cont__options__wrapper__dropdownCard__item__text"
                  style={{
                    color: `${token?.Typography?.baseColorText}`,
                  }}>
                  {record?.payment_type
                    ? options?.find(
                      (payment) => payment?.key === record?.payment_type
                    )?.label
                    : "Payment Type"}
                </Typography.Text>
                <DownOutlined
                  style={{
                    color: `${token?.icons?.baseColor}`,
                  }}
                />
              </div>
            </Dropdown>
          </Can>
        );
      },
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
      className: "table-row-text",
      selected: true,
      filters: possibleLocations?.map((location) => ({
        text: location?.name,
        value: location?.id,
      })),
      filterSearch: true,
      render: (_, record) => {
        return record?.location?.name;
      },
      width: 130,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      selected: true,
      className: classNames("table-row-text", {
        "smallTable-row-text":
          isSmallDesktop || isMiddlesDesktop || width <= 1630,
      }),
      width: 160,
      render: (_, record) => {
        return loading &&
          currentField === "Action" &&
          selectedRowKey === record?.key ? (
          <Spin />
        ) : (
          <Can
            I="change"
            a="order"
            fallback={
              <div className="order-drawer__tottal-cont__options__wrapper__dropdownact__item">
                <Tag
                  className="order-drawer__tottal-cont__options__wrapper__dropdownact__item__text"
                  color={statusMapping(record?.status)}>
                  {/* {orderStatus ? orderStatus : "Unassigned"} */}
                  {Utils?.statusNameMapping(record?.status)}
                </Tag>
              </div>
            }>
            <Dropdown
              className="order-drawer__tottal-cont__options__wrapper__dropdownact"
              trigger={["click"]}
              menu={{
                items: dropdownItemsActions,
                onClick: ({ key }) => {
                  const foundAction = dropdownItemsActions?.find(
                    (el) => key === el?.key
                  );
                  setSelectedRowKey(record?.key);
                  setCurrentField("Action");
                  ammendOrderStatus({
                    status: foundAction?.key,
                    id: record?.key,
                  });
                },
              }}>
              <div className="order-drawer__tottal-cont__options__wrapper__dropdownact__item">
                <Tag
                  className="order-drawer__tottal-cont__options__wrapper__dropdownact__item__text"
                  color={statusMapping(record?.status)}>
                  {/* {orderStatus ? orderStatus : "Unassigned"} */}
                  {Utils?.statusNameMapping(record?.status)}
                </Tag>
                <DownOutlined
                  style={{
                    color: `${statusMapping(record?.status)}`,
                  }}
                />
              </div>
            </Dropdown>
          </Can>
        );
      },
    },
    {
      title: "",
      key: "icons",
      selected: true,
      className: "header-cell-small table-row-text",
      fixed: "right",
      width: 40,
      render: (_, record) => {
        return (
          <div className="tableiconsWrapper">
            <Can I="change" a="order" fallback={<></>}>
              <Button
                className="tableiconsWrapper__wrapper"
                onClick={() => {
                  const order = results?.find((el) => el?.id === record?.key);
                  setSelectedOrder(order);
                  setIsDrawerOpened(true);
                }}
                icon={<EditOutlined className="EditOutlined" />}></Button>
            </Can>
          </div>
        );
      },
      onCell: (record) => {
        return {
          style: {
            borderRightColor:
              record?.customer?.orders_count === 1 ? token?.colorPrimary : null,
          },
        };
      },
    },
  ];

  const [selectedColumns, setSelectedColumns] = useState(
    LocalStorageService?.get("orderTableColumns")
      ? Array.from(LocalStorageService?.get("orderTableColumns"))
      : tableColumns?.filter((item) => item?.selected)?.map((item) => item?.key)
  );

  const ammendOrderStatus = (params) => {
    params = {
      status: params?.status,
      id: params?.id,
    };
    dispatch(updateOrderStatus(params));
  };

  const amendPaymentType = (params) => {
    params = {
      payment_type: params?.payment_type,
      id: params?.id,
    };
    dispatch(updateOrderPaymentType(params));
  };

  const handleDeselectColumns = (e) => {
    setSelectedColumns((oldSelected) =>
      oldSelected.filter((item) => item !== e?.key)
    );
    LocalStorageService?.set(
      "orderTableColumns",
      selectedColumns?.filter((item) => item !== e?.key)
    );
  };

  const handleSelectColumns = (e) => {
    setSelectedColumns((oldSelected) => [...oldSelected, e?.key]);
    LocalStorageService?.set("orderTableColumns", [...selectedColumns, e?.key]);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.synced_with_metrc || !record.is_metrc_order,
      name: record.name,
    }),
  };

  const onSorterIconFildChange = (pagination, filters, sorter) => {
    if (sorter?.columnKey) {
      if (sorter?.order === "ascend") {
        setOrderBy(sorter?.columnKey);
      } else if (sorter?.order === "descend") {
        setOrderBy(`-${sorter?.columnKey}`);
      } else {
        setOrderBy("");
      }
    }
    if (filters?.driver) {
      setDriverFilter(filters?.driver);
    }
    if (filters?.payment_types) {
      setPaymentFilter(filters?.payment_types);
    }
    if (filters?.region) {
      setLocationFilter(filters?.region);
    }
    if (!filters?.driver) {
      setDriverFilter(null);
    }
    if (!filters?.payment_types) {
      setPaymentFilter(null);
    }
    if (!filters?.region) {
      setLocationFilter(null);
    }
    setPage(1);
  };

  function metricOrdersSync() {
    dispatch(reportToMetric({ orders: selectedRowKeys })).then(() => {
      updateOrderWhenCustomerDetailsChanged();
    })
  }

  const handleTableChange = (e) => {
    setPage(e);
  };

  const onShowSizeChange = (e, a, b) => {
    setPerPage(a);
    LocalStorageService.updateObject('pp', 'order', a)
  };

  useEffect(() => {
    setDropdownItemsCards(options);
  }, [options]);

  let updateOrderWhenCustomerDetailsChanged = () => {
    dispatch(removeSocketOrders());
    if (tabKey !== "all") {
      if (searchVal || date_start || date_end) {
        dispatch(
          getRoomOrders({
            id: tabKey,
            page,
            per_page: perPage,
            status: selectedSegment ? selectedSegment : undefined,
            order_by: orderBy,
            driver_id: driverFilter,
            name: searchVal ? searchVal : undefined,
            start_date: date_start ? date_start : undefined,
            end_date: date_end ? date_end : undefined,
            payment_types: paymentFilter,
            locations: locationFilter,
            // is_metrc_order: isByMetrc ? isByMetrc : undefined
          })
        );
      } else {
        dispatch(
          getRoomOrders({
            id: tabKey,
            page,
            per_page: perPage,
            status: selectedSegment ? selectedSegment : undefined,
            order_by: orderBy ? orderBy : undefined,
            driver_id: driverFilter,
            payment_types: paymentFilter,
            locations: locationFilter,
            // is_metrc_order: isByMetrc ? isByMetrc : undefined
          })
        );
      }
    } else {
      if (searchVal || date_start || date_end) {
        dispatch(
          getItems({
            page,
            per_page: perPage,
            status: selectedSegment ? selectedSegment : undefined,
            order_by: orderBy,
            driver_id: driverFilter,
            name: searchVal ? searchVal : undefined,
            start_date: date_start ? date_start : undefined,
            end_date: date_end ? date_end : undefined,
            payment_types: paymentFilter,
            locations: locationFilter,
            // is_metrc_order: isByMetrc ? isByMetrc : undefined
          })
        );
      } else {
        dispatch(
          getItems({
            page,
            per_page: perPage,
            status: selectedSegment ? selectedSegment : undefined,
            order_by: orderBy ? orderBy : undefined,
            driver_id: driverFilter,
            payment_types: paymentFilter,
            locations: locationFilter,
            // is_metrc_order: isByMetrc ? isByMetrc : undefined
          })
        );
      }
    }
  };

  let updateReportwhenOrderItemChanged = () => {
    if (date_start && date_end) {
      dispatch(
        getCardsReports({
          date_start,
          date_end,
        })
      );
    } else if (date_start) {
      dispatch(
        getCardsReports({
          date_start,
        })
      );
    } else if (date_end) {
      dispatch(
        getCardsReports({
          date_end,
        })
      );
    } else {
      dispatch(getCardsReports());
    }
  };

  useEffect(() => {
    const order = results?.find((el) => el?.id === selectedOrder?.id);
    setSelectedOrder(order);
  }, [results]);

  useEffect(() => {
    if (!loading) {
      setCurrentField("");
      setSelectedRowKey(null);
    }
  }, [ammendOrderStatus, amendPaymentType, ammendOrderDriver, loading]);

  useEffect(() => {
    const calculationOfScrollWidth = selectedColumns?.reduce(
      (accumlyator, currentval) => {
        const width = tableColumns?.find(
          (el) => el?.dataIndex === currentval
        )?.width;
        const widthValue = !isNaN(width) ? width : 0;
        return widthValue + accumlyator;
      },
      0
    );
    setScrollWidth(calculationOfScrollWidth);
  }, [selectedColumns]);

  return {
    token,
    isMobile,
    width,
    drivers,
    tableColumns,
    selectedColumns,
    handleDeselectColumns,
    handleSelectColumns,
    totalItems,
    reportLoading,
    isDrawerOpened,
    rowSelection,
    loading,
    currentField,
    results,
    onSorterIconFildChange,
    handleTableChange,
    onShowSizeChange,
    count,
    selectedOrder,
    setIsDrawerOpened,
    setSelectedOrder,
    error,
    contextHolder,
    scrollwidth,
    tableRef,
    updateOrderWhenCustomerDetailsChanged,
    success,
    metricOrdersSync,
    selectedRowKeys,
    updateReportwhenOrderItemChanged,
    roomLoading,
  };
}
