import { createSlice } from "@reduxjs/toolkit";
import { getCardsReports } from "./reportAction";

const initialState = {
  loading: false,
  error: null,
  success: false,
  result: {},
};

const reportSlice = createSlice({
  name: "cardReports",
  initialState,
  reducers: {
    manageLoadingState: (state, action) => {
      state.loading = action?.payload?.loading;
    },
  },
  extraReducers: {
    [getCardsReports.pending]: (state) => {
      state.loading = true;
      state.result = {};
      state.error = null;
    },
    [getCardsReports.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.result = payload;
      state.error = null;
    },
    [getCardsReports.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});
export const { manageLoadingState } = reportSlice.actions;
export default reportSlice.reducer;
