import { theme } from "antd";
import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetTeamMemberError } from "../features/teamMembers/teamMembersSlice";
import Utils from "../utils";

const emailValidator = Utils.emailValidator;

export default function useTeamUserModal(user, isOpened, roles, onCancel) {
  const { useToken } = theme;
  const { token } = useToken();
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const { error, success, loading } = useSelector(
    (state) => state?.teamMembers
  );
  // const [phoneCode, setPhoneCode] = useState("+1");
  const [formData, setFormData] = useState([]);
  const [changedFields, setChangedFields] = useState({});
  const [isFieldsChanged, setIsFieldsChanged] = useState(false);

  // const selectBefore = (
  //   <Select
  //     value={phoneCode ? phoneCode : "Code"}
  //     style={{
  //       width: 72,
  //     }}
  //     onChange={(code) => {
  //       formRef.current.validateFields(["phone"]);
  //       setPhoneCode(code);
  //       if (error && error["phone"]) {
  //         dispatch(resetTeamMemberError("phone"));
  //       }
  //     }}
  //     options={[1].map((el) => ({
  //       value: `+${el}`,
  //       label: `+${el}`,
  //     }))}
  //   />
  // );

  // const phoneCodeValidator = (_, value) => {
  //   return new Promise((resolve, reject) => {
  //     if (!value && !phoneCode) {
  //       reject("Please enter your valid Number");
  //     } else if (!phoneCode && value) {
  //       reject("please enter Phone code");
  //     } else if (!/^\d+$/.test(value)) {
  //       reject("Phone Number must be a whole number.");
  //     } else {
  //       resolve();
  //     }
  //   });
  // };

  const areArraysEqual = (array1, array2) => {
    // Check if arrays have the same length
    if (array1.length !== array2.length) {
      return false;
    }
    // Convert arrays to JSON strings and compare
    const stringifiedArray1 = JSON.stringify(array1);
    const stringifiedArray2 = JSON.stringify(array2);

    return stringifiedArray1 === stringifiedArray2;
  };

  const handleFiledsChanged = (changedField) => {
    if (formData.length !== 0) {
      if (changedFields[changedField?.name] !== changedField?.value) {
        let text = changedField?.name;
        let newFields = {
          ...changedFields,
          [text]: changedField?.value,
        };
        for (let key in newFields) {
          if (key === "groups") {
            if (areArraysEqual(newFields[key], user?.groups)) {
              delete newFields[key];
            }
          } else {
            const foundFild = formData?.find((el) => key === el?.name);
            if (foundFild?.value === newFields[key]) {
              delete newFields[key];
            }
          }
        }
        setChangedFields(newFields);
      }
    } else {
      if (changedFields[changedField?.name] !== changedField?.value) {
        let text = changedField?.name;
        let newFields = {
          ...changedFields,
          [text]: changedField?.value,
        };
        if (
          newFields[changedField?.name] === undefined ||
          newFields[changedField?.name] === ""
        ) {
          delete newFields[changedField?.name];
        }
        setChangedFields(newFields);
      }
      // console.log("changedFields", changedField);
      // console.log("allFields",allFields)
    }
  };

  useEffect(() => {
    if (user) {
      setFormData([
        {
          name: "first_name",
          value: user?.first_name ? user?.first_name : "",
        },
        {
          name: "last_name",
          value: user?.last_name ? user?.last_name : "",
        },
        {
          name: "phone",
          value: user?.phone ? user?.phone : "",
        },
        {
          name: "email",
          value: user?.email ? user?.email : "",
        },
        {
          name: "groups",
          value: user?.groups ? user?.groups : "",
        },
        {
          name: "telegram_token",
          value: user?.telegram_token ? user?.telegram_token : "",
        },
      ]);
      // setPhoneCode(user?.phone?.slice(0, 2));
    }
  }, [isOpened, user]);

  useEffect(() => {
    if (isOpened) {
      if (error && !loading) {
        for (let key in error) {
          formRef?.current?.setFields([
            {
              name: key, // Field name you want to set the error for
              errors: [error[key][0]], // Assuming your error has a 'message' field
            },
          ]);
        }
      }
    }
  }, [loading, error]);

  useEffect(() => {
    if (isOpened) {
      if (success) {
        onCancel();
        formRef.current.resetFields();
        dispatch(resetTeamMemberError());
        setIsFieldsChanged(false);
        setChangedFields({});
      }
    }
  }, [success, dispatch]);

  useEffect(() => {
    if (user) {
      if (Object.keys(changedFields).length !== 0) {
        setIsFieldsChanged(true);
      } else {
        setIsFieldsChanged(false);
      }
    }
  }, [changedFields]);

  const filterGroupOption = (input, option) => {
    return (option?.label ?? "")?.toLowerCase()?.includes(input?.toLowerCase());
  };

  return {
    token,
    formRef,
    emailValidator,
    formData,
    loading,
    error,
    dispatch,
    resetTeamMemberError,
    handleFiledsChanged,
    isFieldsChanged,
    setIsFieldsChanged,
    setChangedFields,
    filterGroupOption,
  };
}
